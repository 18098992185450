import { connect } from 'react-redux';

import { fetchMoreComponentsAndProducts } from 'redux/modules/search-and-browse/actions/fetch-more-components-and-products';
import { getDisplayedProducts } from 'redux/modules/search-and-browse/selectors/get-displayed-products';
import { getUniqueSponsoredItemsCount } from 'redux/modules/search-and-browse/selectors';

import LoadMoreButton from './LoadMoreButton';

export default connect(
  state => ({
    hasMore: state.searchAndBrowse.hasMore,
    loading: state.searchAndBrowse.loadingMore,
    start: getDisplayedProducts(state) - getUniqueSponsoredItemsCount(state),
    searchType: state.searchAndBrowse.request?.name,
  }),
  {
    fetchMore: fetchMoreComponentsAndProducts,
  },
)(LoadMoreButton);
