import { connect } from 'react-redux';

import { getParentCategoryName, getParentCategoryUrl } from 'redux/modules/taxonomy/selectors';
import getReasonForEmptyProductGrid from 'redux/modules/search/selectors/get-reason-for-empty';
import { withRouteProps } from 'utils/with-route-props';

import ProductGridEmpty from './ProductGridEmpty';

export default withRouteProps(
  connect((state, props) => ({
    pageType: 'category',
    parentName: getParentCategoryName(state, props),
    parentUrl: getParentCategoryUrl(state, props),
    reason: getReasonForEmptyProductGrid(state),
  }))(ProductGridEmpty),
);
