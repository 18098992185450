import React from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import styles from './index.scss';

export default function GroceriesLegalNotice() {
  return (
    <div className={styles.legalNotice}>
      <article className="container-fluid">
        <Typography element="h2" styleAs="paragraphSmall">
          Substitution of sunflower oil in certain products
        </Typography>
        <Typography styleAs="paragraphSmallLight">
          Please be aware that some food products labelled as containing sunflower oil may instead
          contain highly refined rapeseed, palm, soya or coconut oils. This is in order to maintain
          the supply of certain food products containing ingredients that have become increasingly
          difficult to source because of the conflict in Ukraine. Some of our suppliers have had to
          replace sunflower oil with other highly refined oils before being able to make the change
          on the label. Please be assured that where labels cannot be updated before the
          substitution is made, no oil that poses an allergenic risk will be used. If you have any
          concerns regarding these substitutions, please contact{' '}
          <a href="mailto:customerserviceteam@waitrose.co.uk" title="Customer service e-mail">
            customerserviceteam@waitrose.co.uk
          </a>
        </Typography>
      </article>
    </div>
  );
}
