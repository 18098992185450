import { connect } from 'react-redux';

import {
  getGridItems,
  getShowPageTitle,
  getVisibleSubCategories,
} from 'redux/modules/search-and-browse/selectors';
import { getLocationBreadcrumbs, getTitle } from 'redux/modules/taxonomy/selectors';
import { hasLocationsMasthead } from 'redux/modules/content/selectors';

import BrowseHeader from './BrowseHeader';

export default connect((state, props) => ({
  breadcrumbs: getLocationBreadcrumbs(state, props),
  products: getGridItems(state),
  showTitle: getShowPageTitle(state),
  splitTitle: hasLocationsMasthead(state),
  subCategories: getVisibleSubCategories(state, props),
  title: getTitle(state, props),
}))(BrowseHeader);
