import React from 'react';

import ErrorPanel from 'components/Error/ErrorPanel';
import { useResponse } from 'components/ResponseProvider';

const NotFound = () => {
  const { setStatus } = useResponse();
  setStatus(404);

  return (
    <div className="container">
      <ErrorPanel problem="404 Not Found">
        Sorry, the requested resource was not found. Please try again.
      </ErrorPanel>
    </div>
  );
};

NotFound.displayName = 'NotFound';

export default NotFound;
