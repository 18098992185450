import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import Canonical from 'components/Canonical';

const MetaHeader = ({
  absoluteCanonical,
  canonicalHref,
  description,
  keywords,
  notIndexed,
  pageTitle,
  title,
}) => {
  const metaProperties = [
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
  ];

  if (notIndexed) {
    metaProperties.push({ name: 'robots', content: 'noindex' });
  }

  return (
    <Fragment>
      <Helmet meta={metaProperties} title={title || pageTitle.text} />
      {canonicalHref && <Canonical absolute={absoluteCanonical} href={canonicalHref} />}
    </Fragment>
  );
};

MetaHeader.displayName = 'MetaHeader';

MetaHeader.propTypes = {
  absoluteCanonical: PropTypes.bool,
  canonicalHref: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  notIndexed: PropTypes.bool,
  pageTitle: PropTypes.shape({
    text: PropTypes.string,
  }),
  title: PropTypes.string,
};

MetaHeader.defaultProps = {
  absoluteCanonical: false,
  canonicalHref: null,
  description: null,
  keywords: null,
  notIndexed: false,
  pageTitle: {},
  title: null,
};

export default MetaHeader;
