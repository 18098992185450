import { connect } from 'react-redux';

import { getCustomerSlotBranchId } from 'redux/modules/customer-slot/selectors';

import { getLocationsLoading, hasLocationsSEOContent } from 'redux/modules/content/selectors/index';

import { getLocationCategory, getLocationBreadcrumbs } from 'redux/modules/taxonomy/selectors';
import { fetchBrowseProducts } from 'redux/modules/browse/actions';
import {
  getSearchAndBrowseLoading,
  getRecommendations,
} from 'redux/modules/search-and-browse/selectors';
import { getPageLoading } from 'redux/modules/page/selectors';
import {
  isPendingOrdersLoading,
  isPendingOrdersLoaded,
} from 'redux/modules/orders/selectors/get-orders';
import { withRouteProps } from 'utils/with-route-props';

import Browse from './Browse';

export default withRouteProps(
  connect(
    (state, props) => ({
      breadcrumbs: getLocationBreadcrumbs(state, props),
      category: getLocationCategory(state, props),
      customerSlotBranchId: getCustomerSlotBranchId(state),
      locationsLoading: getLocationsLoading(state),
      productsLoading: getSearchAndBrowseLoading(state),
      recommendations: getRecommendations(state),
      hasSEOContent: hasLocationsSEOContent(state),
      isPageLoading: getPageLoading(state),
      isPendingOrdersLoading: !isPendingOrdersLoaded(state) && isPendingOrdersLoading(state),
    }),
    (dispatch, { location }) => ({
      fetchProducts: category => fetchBrowseProducts(category, location)(dispatch),
    }),
  )(Browse),
);
