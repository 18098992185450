export const sortByOptions = {
  browse: [
    {
      label: 'Popularity',
      value: 'MOST_POPULAR',
      order: 1,
    },
    {
      label: 'Top rated',
      value: 'TOP_RATED',
      order: 2,
    },
    {
      label: 'Alphabetical',
      value: 'A_2_Z',
      order: 3,
    },
    {
      label: 'Alphabetical reverse',
      value: 'Z_2_A',
      order: 4,
    },
    {
      label: 'Price low to high',
      value: 'PRICE_LOW_2_HIGH',
      order: 5,
    },
    {
      label: 'Price high to low',
      value: 'PRICE_HIGH_2_LOW',
      order: 6,
    },
  ],
  customAisle: [
    {
      label: 'Default',
      value: 'COLLECTION_ORDER',
      order: 1,
    },
    {
      label: 'Popularity',
      value: 'MOST_POPULAR',
      order: 2,
    },
    {
      label: 'Top rated',
      value: 'TOP_RATED',
      order: 3,
    },
    {
      label: 'Alphabetical',
      value: 'A_2_Z',
      order: 4,
    },
    {
      label: 'Alphabetical reverse',
      value: 'Z_2_A',
      order: 5,
    },
    {
      label: 'Price low to high',
      value: 'PRICE_LOW_2_HIGH',
      order: 6,
    },
    {
      label: 'Price high to low',
      value: 'PRICE_HIGH_2_LOW',
      order: 7,
    },
  ],
  offers: [
    {
      label: 'Popularity',
      value: 'MOST_POPULAR',
      order: 1,
    },
    {
      label: 'Top rated',
      value: 'TOP_RATED',
      order: 2,
    },
    {
      label: 'Alphabetical',
      value: 'A_2_Z',
      order: 3,
    },
    {
      label: 'Alphabetical reverse',
      value: 'Z_2_A',
      order: 4,
    },
    {
      label: 'Price low to high',
      value: 'PRICE_LOW_2_HIGH',
      order: 5,
    },
    {
      label: 'Price high to low',
      value: 'PRICE_HIGH_2_LOW',
      order: 6,
    },
  ],
  search: [
    {
      label: 'Relevance',
      value: 'RELEVANCE',
      order: 1,
    },
    {
      label: 'Popularity',
      value: 'MOST_POPULAR',
      order: 2,
    },
    {
      label: 'Top rated',
      value: 'TOP_RATED',
      order: 3,
    },
    {
      label: 'Alphabetical',
      value: 'A_2_Z',
      order: 4,
    },
    {
      label: 'Alphabetical reverse',
      value: 'Z_2_A',
      order: 5,
    },
    {
      label: 'Price low to high',
      value: 'PRICE_LOW_2_HIGH',
      order: 6,
    },
    {
      label: 'Price high to low',
      value: 'PRICE_HIGH_2_LOW',
      order: 7,
    },
  ],
};
