import {
  getCanonicalHref as taxonomyCanonical,
  getMetaDescription as taxonomyDescription,
} from 'redux/modules/taxonomy/selectors';
import {
  getMetaDataCanonical as searchAndBrowseCanonical,
  getMetaDataDescription as searchAndBrowseDescription,
} from 'redux/modules/search-and-browse/selectors/meta-data';

export const getCategoryPageCanonical = (state, props) =>
  searchAndBrowseCanonical(state) || taxonomyCanonical(state, props);

export const getCategoryPageDescription = (state, props) =>
  searchAndBrowseDescription(state) || taxonomyDescription(state, props);
