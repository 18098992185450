import { createSelector } from 'reselect';

import urls from 'constants/urls';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import { getExperimentValueById } from 'redux/modules/experiments/selectors';
import isAmendingOrderSelector from 'redux/modules/trolley/selectors/amending-order';
import { isSeasonalSlotDate } from 'utils/checkout/is-seasonal-slot-date';
import { getFeatureFlags } from 'utils/feature-flags';

const isExperimentVariant = (experience: string, variant: string) => (state: WtrState) =>
  getExperimentValueById(state, experience) === variant;

export const isChristmasHubEnabled = createSelector(
  isAmendingOrderSelector,
  getCustomerSlotDate,
  isExperimentVariant('EoS_ChristmasHub', 'showHub'),
  (amendingOrder, slotDate, christmasHubFlag) => {
    const { EoS_ChristmasHub_ForceValidDate: forceValidDate } = getFeatureFlags();
    const dateIsValid = forceValidDate || isSeasonalSlotDate(slotDate);

    return christmasHubFlag && dateIsValid && amendingOrder;
  },
);

export const isChristmasHubSelector = createSelector(
  [isChristmasHubEnabled, (_state, pathname) => pathname],
  (christmasHubIsEnabled, pathname = '') => {
    const isChristmasHubUrl = pathname.toLowerCase() === urls.groceriesChristmas;
    return isChristmasHubUrl && christmasHubIsEnabled;
  },
);
