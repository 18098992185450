import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ErrorPanel from 'components/Error/ErrorPanel';

const ProductGridEmpty = memo(({ pageType, parentName, parentUrl, reason }) => (
  <ErrorPanel
    message
    problem={`Sorry, there are currently no products available in this ${pageType}`}
    reason={reason && `(${reason})`}
  >
    {parentName && parentUrl && (
      <Link to={parentUrl}>Take a look at other products in {parentName}</Link>
    )}
  </ErrorPanel>
));

ProductGridEmpty.propTypes = {
  pageType: PropTypes.string.isRequired,
  parentName: PropTypes.string,
  parentUrl: PropTypes.string,
  reason: PropTypes.string,
};

ProductGridEmpty.defaultProps = {
  reason: undefined,
  parentUrl: undefined,
  parentName: undefined,
};

export default ProductGridEmpty;
