import { connect } from 'react-redux';

import { getCurrentPageDepth } from 'redux/modules/page/selectors';
import { getGridItems, getSearchAndBrowseLoading } from 'redux/modules/search-and-browse/selectors';
import { getSearchAndBrowseSearchType } from 'redux/modules/search-and-browse/selectors/search';
import { resetError } from 'redux/modules/search-and-browse/actions/reset-error';

import SearchAndBrowseLoadMoreButton from 'components/LoadMoreButton';

import ProductGrid from './ProductGrid';

export default connect(
  (state, props) => ({
    loading: getSearchAndBrowseLoading(state),
    LoadMoreButton: SearchAndBrowseLoadMoreButton,
    products: getGridItems(state),
    searchType: getSearchAndBrowseSearchType(state),
    taxonomyLevel: getCurrentPageDepth(state),
    ...props,
  }),
  {
    resetError,
  },
)(ProductGrid);
