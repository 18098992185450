import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';

export default ({
  root = null,
  rootMargin,
  threshold = 0,
}: IntersectionObserverInit): [
  Dispatch<SetStateAction<Element | null>>,
  IntersectionObserverEntry,
] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>({} as IntersectionObserverEntry);
  const [node, setNode] = useState<Element | null>(null);

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    const callback = ([change]: IntersectionObserverEntry[]) => updateEntry(change);

    observer.current = new window.IntersectionObserver(callback, {
      root,
      rootMargin,
      threshold,
    });

    const { current: currentObserver } = observer;

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node, root, rootMargin, threshold]);

  return [setNode, entry];
};
