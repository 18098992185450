import { connect } from 'react-redux';

import { getMetaKeywords, getTitle } from 'redux/modules/taxonomy/selectors';
import {
  getMetaDataCanonical,
  getMetaDataNoIndex,
  getMetaDataTitle,
} from 'redux/modules/search-and-browse/selectors/meta-data';
import {
  getCategoryPageCanonical,
  getCategoryPageDescription,
} from 'redux/modules/search-and-browse/selectors/category-page-metadata';

import MetaHeader from 'components/MetaHeader';

export default connect((state, props) => ({
  absoluteCanonical: !!getMetaDataCanonical(state),
  canonicalHref: getCategoryPageCanonical(state, props),
  description: getCategoryPageDescription(state, props),
  keywords: getMetaKeywords(state, props),
  notIndexed: getMetaDataNoIndex(state),
  pageTitle: getTitle(state, props),
  title: getMetaDataTitle(state),
}))(MetaHeader);
